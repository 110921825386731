.Login-form {
    max-width: 300px;
}

.Login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.Login-form-button {
    width: 100%;
}

.Login-form-textBox {
    text-align: center;
    font-size: 13px;
}

.Login-form-guide-textBox {
    text-align: center;
    font-size: 13px;
}

.qr-container {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.qr-box {
    display: inline-block;
    padding: 20px;
    border: 2px solid black;
    position: relative;
}

.input-section {
    text-align: center;
    margin-top: 20px;
}