.Main-menu-logo {
    height: 20px;
    margin: 15px;
    justify-content: center;
    text-align: center;
}

.Main-content-header {
    width: 100%;
    text-align: end;
    padding-right: 30px;
}